import {Carousel} from "@fancyapps/ui";
import {Autoplay} from "@fancyapps/ui/dist/carousel/carousel.autoplay.esm";

const container = document.getElementById("banners");
const options = {
	Autoplay: {
		timeout: 3000,
		pauseOnHover: false,
	},
	infinite: true
};

window.carousel = new Carousel(container, options,{ Autoplay});
